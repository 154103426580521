<template>
  <v-container fluid>
    <v-card
      outlined
      class="mt-2"
    >
      <v-card-title class="d-flex justify-space-between align-start">
        <div>Отчеты</div>
        <WidgetMenu/>
      </v-card-title>
    </v-card>
  </v-container>
</template>

<script>

import WidgetMenu from '@/components/views/account/dashboard/old/WidgetMenu'

export default {
  name: 'Report',
  components: { WidgetMenu }
}
</script>

<style scoped>

</style>
