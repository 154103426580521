<template>
  <v-menu
    :close-on-content-click="false"
    :nudge-width="200"
    offset-x
  >
    <template v-slot:activator="{ on }">
      <v-btn
        icon
        color="primary"
        v-on="on"
      >
        <v-icon>mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-card
      outlined
      rounded="0"
      elevation="0"
    >
      <v-card-text>
        <v-alert>
          <v-btn
            small
            color="primary"
          >
            <v-icon
              small
              class="mr-2"
            >mdi-file-document
            </v-icon>
            Скачать ведомость
          </v-btn>
        </v-alert>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  name: 'WidgetMenu'
}
</script>

<style scoped>

</style>
